@import "../../theme/index.module.scss";

.LoadMore {
  margin-top: 1.45em;
  margin-bottom: 1.45em;
  text-align: center;

  a {
    background: transparent;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    color: $black;
  }
}
